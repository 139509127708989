/**
* Generated automatically at built-time (2025-03-03T15:20:23.512Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-weinkuehlschrank",templateKey: "sites/85-07f5774d-779b-47d5-a7af-dfc97807c137"};